
import "@babel/polyfill";
import { start } from 'single-spa';
import { registerConfigAsync } from './core/RegisterApp';
import App from './App.vue';
import routes from './routes/routes';
import store from './store';
import { getAppConf } from '@/tools/utils';
import AppConfig from './app.config'
const appConfig = getAppConf();
window.$request.commonHeaders = {
  clientId: appConfig.clientId
};

import './assets/styles/base.less';
// 全局新增图标库
import './assets/iconfont/iconfont.css';

const Vue = window.Vue;
const GlobalStore = window.GlobalStore;
const router = window.router;
const EventEmitter = window.EventEmitter;


// 设置全局状态, portal启动时为true
window.IS_REGIST_PORTAL = true;
// 添加路由
router.addRoutes(routes);

EventEmitter.on('iframe_logout', (status) => {
  if (status) {
    GlobalStore.dispatch('rmSession');
    router.replace(`${AppConfig.publicPath}/login`);
  }
})

// 添加module
if (store && Object.keys(store).length > 0) {
  for (let s in store) {
    GlobalStore.registerModule(s, store[s]);
  }
}

async function bootstrap() {
  // 加载配置文件
  let projectConfig = window.projectConfig;

  await registerConfigAsync(projectConfig.projects);

  await start();
}

new Vue({
  el: '#app',
  router,
  store:  GlobalStore,
  async mounted() {
    await bootstrap();
  },
  render: h => h(App)
});


