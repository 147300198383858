export default [
  {
    path: '/login',
    components: {
      portal: () => import('@/pages/login')
    }
  },
  {
    path: '/enterprise_regist/:id?',
    components: {
      portal: () => import('@/pages/Enterprise/Enterprise'),
    }
  },
  {
    path: '/data_charts/:id?',
    components: {
      portal: () => import('@/pages/dataCharts'),
    }
  },
  {
    path: '/tip',
    components: {
      portal: () => import('@/pages/Success'),
    }
  },
  {
    path: '/active/:tenant/:code',
    components: {
      portal: () => import('@/pages/active')
    },
  },
  {
    path: '/usercenter',
    components: {
      root: () => import('@/pages/usercenter')
    }
  },
  {
    path: '/modifypwd',
    components: {
      root: () => import('@/pages/modifypwd')
    }
  },
  {
    path: '/external',
    name: 'external',
    components: {
      root: () => import('@/pages/iframe')
    },
    meta: {
      title: '外链',
      portal: true
    }
  }
]