<template>
  <Layout :style="{minHeight: '100vh'}" class="menu-bg">
    <Sider
      hide-trigger
      collapsible
      :width="200"
      :collapsed-width="80"
      v-model="collapsed"
      class="left-sider menu-bg"
      :style="{overflow: 'hidden'}"
    >
      <CollapsedMenu
        style="height: 100vh;"
        accordion
        ref="sideMenu"
        :collapsed="collapsed"
        :menu-list="routes"
        menu-class="scrollbar"
      >
        <div class="logo" v-show="!collapsed">
          <img :src="maxLogo" />
          <div class="sys-tit">
            <p>安全生产</p>
            <p>信息化管理平台</p>
          </div>
        </div>
        <img
          v-show="collapsed"
          :src="minLogo"
          key="min-logo"
          style="width:26px;height: auto;display:block;margin:10px auto 0;"
        />
        <!--        <template slot="_个人中心">123123</template>-->
        <!--        <template slot="_project-mine_upcoming_list">123123</template>-->
        <template slot="_个人中心">
          <span class="count" v-if="count > 0">{{count}}</span>
        </template>
        <template slot="_project-mine_upcoming_list">
          <span class="sub_count" v-if="count > 0">{{count}}</span>
        </template>
        <!--        <template slot="_project-mine_done_list">123123</template>-->
        <template slot="footer">
          <div>
            <div class="outlogin" @click="logout" v-show="!collapsed">
              <i class="iconfont tuichudenglu"></i>
              <span>退出登录</span>
            </div>
            <div class="collapsed_logout" @click="logout" v-show="collapsed">
              <Tooltip content="退出登录" placement="right" style="left: 80px;" transfer theme="dark">
                <i class="iconfont tuichudenglu" />
              </Tooltip>
            </div>
          </div>
        </template>
      </CollapsedMenu>
    </Sider>
    <Layout class-name="test-class">
      <Affix>
        <Header
          :style="{background: menuType === 2 ? '#fff' : '#212121',color: menuType === 2 ? '@Regular-text-color' : '#2BD9CF'}"
          class="layout-header-bar"
          ref="header"
        >
          <Row type="flex" justify="space-between">
            <Col>
              <Row type="flex" justify="start" class="code-row-bg">
                <Col>
                  <i
                    class="iconfont Group"
                    @click="toggleCollapse"
                    size="20"
                    style="cursor: pointer;margin-right: 20px;"
                  />
                  <router-link :to="rootPath" style="margin-right: 20px;">
                    <i class="iconfont home" style="color: #000;" />
                  </router-link>

                  <!-- 这是面包屑，打开就能用 -->
                  <AiBreadCrumb :routes="breadCrumbList" class="portal-bread" />
                </Col>
                <Col>
                  <Menu
                    mode="horizontal"
                    :active-name="rootActiveName"
                    v-if="menuType==3"
                    theme="dark"
                    @on-select="menuSelect"
                  >
                    <MenuItem :name="item.id" v-for="item in rootTitles" :key="item.id">
                      <i :class="item.icon" />
                      {{ item.name }}
                    </MenuItem>
                  </Menu>
                </Col>
              </Row>
            </Col>
            <Col>
              <!-- 搜索和消息图标
          <Icon type="ios-search-outline font-14"></Icon>
              <Icon type="ios-notifications-outline font-14" style="margin-left: 10px"></Icon>-->
                <Dropdown trigger="click" style="margin-left: 10px;" id="personal-box" @on-click="menuClick">
                  <div class="cursor-pointer photo">
                    <img src="../assets/img/photo.jpg" />
                    <div class="f-l">{{ userInfo.nickName || userInfo.name }}</div>
                    <Icon type="ios-arrow-down"></Icon>
                  </div>
                  <DropdownMenu slot="list">
                    <DropdownItem v-for="action in rootActions" :key="action.id">
                      <router-link
                        :to="action.path"
                        v-if="action.path"
                        style="display: block;padding: 7px 16px;margin: -7px -16px;"
                      >{{ action.name }}</router-link>
                      <template v-else>{{ action.name }}</template>
                    </DropdownItem>
                    <DropdownItem divided name="logout">退出登录</DropdownItem>
                  </DropdownMenu>
                </Dropdown>
            </Col>
          </Row>
        </Header>
      </Affix>
      <!-- 页签还有bug，慎用 -->
      <!-- <div style="height: 63px;">
        <TagsNav :homePath="rootPath" />
      </div> -->

      <Content :style="{padding: '16px', overflow: 'auto', height: contentHeight, backgroundColor: '#F9FAFC'}" class="portal_content_scroll">
        <slot />
      </Content>
      <Footer> <div class="copyright">Copyright © 2020  内蒙古太平矿业有限公司 All Rights Reserved <a target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index">蒙ICP备14004058号-2</a> </div></Footer>
    </Layout>
  </Layout>
</template>
<script>
  const { mapGetters, mapActions, mapState } = window.Vuex;
  const logo = require("@/assets/img/logo__.png");
  const { menuType } = window.projectConfig;
  const getParent = (dom) => {
    if (dom.className.indexOf('ivu-menu-submenu') >= 0) return dom;
    const parentNode = dom.parentNode;
    return getParent(parentNode);
  }
  export default {
    name: "PortalLayout",
    data() {
      return {
        sec: 60,
        count: 0,
        IS_ROOT: !window.IS_REGIST_PORTAL,
        collapsed: false,
        maxLogo: logo,
        minLogo: logo,
        menuType,
        contentHeight: "calc(100vh - 56px)", //120px
        externals: [
          {
            name: ""
          }
        ]
      };
    },
    computed: {
      ...mapGetters([
        "systemName",
        "rootTitles",
        "routes",
        "rootActions",
        "infomationObject",
        "rootActiveName",
        "tenantId",
        "rootPath",
        "session"
      ]),
      ...mapState({
        userInfo: state => state.portal.userInfo
      }),
      breadCrumbList() {
        return this.routes.concat([
          {
            name: "usercenter",
            path: "/usercenter",
            meta: {
              title: "用户信息查看",
              icon: ""
            }
          },
          {
            name: "modifypwd",
            path: "/modifypwd",
            meta: {
              title: "修改密码",
              icon: ""
            }
          }
        ]);
      }
    },
    methods: {
      ...mapActions([
        "rmSession",
        "setRootActiveName",
        "setActiveName",
        "setOpenNames",
        "setRoutes",
        "setTenantId",
        "logoutPortal"
      ]),
      ...mapActions("portal",[
        "logoutPortal",
        "findCount"
      ]),
      menuClick(name) {
        if (name == 'logout') {
          this.logout()
        }
      },
      menuSelect(name) {
        for (let item of this.rootTitles) {
          if (item.id === name) {
            this.setRootActiveName(name);
            this.setRoutes(item.children);
          }
        }
      },
      toggleCollapse() {
        this.collapsed = !this.collapsed;
      },
      logout() {
        this.$AiModal.confirm({
          title: "温馨提示",
          content: "是否要退出系统?",
          onOk: async () => {
            this.$AiModal.remove();
            const clientId = localStorage.clientId;
            const appName = localStorage.appName;
            const clientSecret = localStorage.clientSecret;
            this.$request.ignoreResponseApis.push('/oauth2/oauth/logout')
            await this.logoutPortal({ token: this.session.token });
            this.rmSession();
            this.setRoutes([]);
            window.location.href = `/portal/login`;
            // this.$router.replace(`/login`);
            this.setTenantId(this.tenantId);
          }
        });
      },
      selectHeaderMenu(activeName) {
        this.setRootActiveName(activeName);
      },
      addClick() {
        setTimeout(() => {
          const scrollbar = document.querySelector('.scrollbar');
          const dom = [...document.querySelectorAll('.ivu-menu-item'), ...document.querySelectorAll('.ivu-menu-submenu-title')]
          dom.forEach(item => {
            item.addEventListener('click', function (e) {
              e.stopPropagation();
              e.preventDefault();
              const y = getParent(e.target).offsetTop;
              scrollbar.scrollTo(0, y);
              return false;
            })
          })
        }, 500)
      },
      async getCount() {
        if (this.timer) {
          clearInterval(this.timer);
          this.timer = null;
        }
        const count = await this.findCount();
        this.count = count;
        this.timer = setInterval(async () => {
          const count = await this.findCount();
          this.count = count;
        },1000 * this.sec)
      }
    },
    watch: {
      routes(n) {
        if (n.length) {
          this.addClick();
        }
      }
    },
    mounted() {
      this.getCount();
      if (this.routes.length) {
        this.addClick();
      }
    },
    destroyed() {
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
      }
    }
  };
</script>

<style scoped lang='less'>
  .layout {
    background: #212121;
    position: relative;
    border-radius: 4px;
  }

  .f-l {
    float: left;
  }
  .font-14 {
    font-size: 14px;
  }
  .color-fff {
    color: #fff;
  }
  .layout-header-bar {
    background: #ffffff;
    box-shadow: 0 2px 6px rgba(0, 21, 41, 0.12);
    line-height: 46px;
    height: 46px;
    padding: 0 22px;
  }

  .iconfont.Group {
    font-size: 14px;
  }
  .photo img {
    width: 18px;
    height: 18px;
    border-radius: 18px;
    margin: 14px 6px 0 0;
    float: left;
  }
  .logo {
    text-align: center;
    display: -webkit-inline-box;
    padding-left: 20px;
    border-bottom: solid 1px #353535;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .logo .sys-tit {
    padding: 5px 0;
    flex: 1;
    font-size: 18px;
    color: #fff;
    font-weight: bold;
    word-break: break-word;
  }
  .logo img {
    width: 32px;
    height: auto;
    margin-right: 10px;
  }
  .outlogin,
  .collapsed_logout {
    color: #b6b6b6;
    font-size: 16px;
    width: 100%;
    padding: 14px 24px;
    cursor: pointer;
    border-top: solid 1px #353535;
    &:hover {
      color: #fff;
    }
  }
  .collapsed_logout {
    padding: 0;
  }
  .outlogin i {
    padding-right: 8px;
  }
  .count, .sub_count {
    display: inline-block;
    margin-left: 10px;
    width: 16px;
    height: 16px;
    background-color: #FF0202;
    text-align: center;
    line-height: 16px;
    font-size: 12px;
    color: #fff;
    border-radius: 50%;
  }
  .sub_count {
    background-color: #FFEFE6;
    color: #FF0202;
  }
  .portal_content_scroll::-webkit-scrollbar {
    width: 0px;
  }
  .portal_content_scroll::-webkit-scrollbar-thumb {
    background: transparent;
  }
  .portal_content_scroll::-webkit-scrollbar-track {
    background: transparent;
  }
  .copyright{
    text-align: center;
  }
</style>



