import { registerApplication } from 'single-spa';
import dynamicFile from '@/libs/dynamic-file';
import '@/libs/system';
const utils = window.utils;
// hash 模式,项目路由用的是hash模式会用到该函数
export function hashPrefix(app) {
  return function (location) {
    let isShow = false
    //如果该应用 有多个需要匹配的路劲
    if (utils.is_array(app.path)) {
      app.path.forEach(path => {
        if (location.hash.startsWith(`#${path}`)) {
          isShow = true
        }
      });
    }
    // 普通情况
    else if (location.hash.startsWith(`#${app.path || app.url}`)) {
      isShow = true
    }
    return isShow;
  }
}

// pushState 模式
export function pathPrefix(app) {
  return function (location) {
    let isShow = false
    //如果该模块 有多个需要匹配的路径
    if (utils.is_array(app.path)) {
      app.path.forEach(path => {
        if (location.pathname.indexOf(`${path}`) === 0) {
          isShow = true
        }
      });
    }
    // 普通情况
    else if (location.pathname.indexOf(`${app.path || app.url}`) === 0) {
      isShow = true
    }
    return isShow;
  }
}

// 异步应用注册
export async function registerApp(params) {
  
  if(process.env.NODE_ENV === 'production') {
    if (params.css) {
      dynamicFile([
        params.css
      ])
    }
  }
  // 第三个参数为,该模块是否显示
  registerApplication(
    params.name,  // 模块名字
    () => SystemJS.import(params.main), // 模块渲染的入口文件
    pathPrefix(params), // 模块显示的条件
    { path: params.path }
  );
}

// 异步配置文件应用注册
export const registerConfigAsync = async (apps) => {
  await Promise.all(apps.map(element =>
    registerApp({
      name: element.root,
      main: `/${element.root}/app.js`,
      base: element.base,
      path: element.path,
      css: `/${element.root}/app.css`
    })))
}