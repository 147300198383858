const $http = window.$request
import { getAppConf } from '@/tools/utils';

const appConfig = getAppConf();
const appName = '/' + appConfig.appName;

// 获取当前用户信息
export const getUserInfo =()=> $http.get(`${appName || process.env.VUE_APP_API_PREFIX}/portal/userInfo`);

// 用户修改密码
export const updatepass = (data) => $http.post(`${appName || process.env.VUE_APP_API_PREFIX}/user/modify/password`, data);


// 获取当前用户菜单列表
export const getUserAuthoritedUIEntities=() => $http.get(`${appName || process.env.VUE_APP_API_PREFIX}/portal/authoritedEntities?type=1`);


export const getEparchyTree = (parentId) => $http.get(`${appName || process.env.VUE_APP_API_PREFIX}/area/q/eparchyTree/${parentId}`);


// 通过password 方式登录oauth2 服务
export const login = (data) =>$http.get('/oauth2/oauth/token', data);

// 通过password 方式登录oauth2 服务
export const logout = (data) =>$http.get('/oauth2/oauth/logout', data);
// 获取首页
export const getRootPathByServer = () => $http.get(`${appName || process.env.VUE_APP_API_PREFIX}/userResource?resouceType=home`)

export const findCount = () => $http.post(`${appName || process.env.VUE_APP_API_PREFIX}/rest/task/findCount`)

