

const $trim = (str = '') => {
  return str.replace(/\s+/g, '');
}

const findRoute = (usedRoutes, rChild) => {
  for (let R of usedRoutes) {
    let routePath = rChild.path.substr(utils.findIndex(rChild.path, '/', 1) + 1).toLowerCase();
    // 并不是嵌套在内层
    if (utils.findIndex(routePath, '/', 0) === -1) {
      if (R.path.toLowerCase().indexOf(routePath) > -1) {
        return R;
      }

      if (R.children && R.children.length) {
        return findRoute(R.children, rChild);
      }
    } else {
      // 嵌套在内层需要先找到路由的上层，然后继续往下找
      let parentRoutePath = routePath.substr(0, utils.findIndex(routePath, '/', 0)).toLowerCase();
      let childRoutePath = routePath.substr(utils.findIndex(routePath, '/', 0) + 1).toLowerCase();
      if (R.path.toLowerCase().indexOf(parentRoutePath) > -1) {
        rChild.path = childRoutePath;
        return findRoute(R.children || [], rChild);
      }
    }
  }
}

export const mapMenuList = (routes, routerObj) => {
  for (let route of routes) {
    if (!route.meta) {
      route.meta = {
        title: route.name,
        icon: route.icon,
      }
    }
    // 由于跳转使用name属性跳转所以需要给父级和自己一个相对name进行router动态注册
    let nName = utils.uuid();
    if (!route.name) {
      route.name = nName;
    } else {
      route.name = nName + route.name;
    }
    if (route.children && route.children.length) {
      for (let rChild of route.children) {
        // 对返回路由进行去空格操作
        rChild.path = $trim(rChild.path);
        rChild.icon = $trim(rChild.icon);
        rChild.subAppURL = $trim(rChild.subAppURL);
        rChild.subAppalias = $trim(rChild.subAppalias);
        // 子项目注册完成
        if (routerObj[`/${rChild.subAppalias}`]) {
          const cRoutes = routerObj[`/${rChild.subAppalias}`].routes;
          let usedRoutes = [];
          if (cRoutes[0].redirect) {
            usedRoutes = cRoutes[1].children
          } else {
            usedRoutes = cRoutes[0].children
          }
          // 遍历已注册路由进行菜单匹配
          const resRoute = findRoute(usedRoutes, rChild);
          if (!resRoute.meta) {
            resRoute.meta = {};
          }
          resRoute.meta.title = rChild.name;
          rChild.name = resRoute.name;
          rChild.meta = resRoute.meta;
        }
      }
    }
  }
  return JSON.parse(JSON.stringify(routes))
}

export const getQuery = (searchStr) => {
  let data = {};
  let str = searchStr;
  if (searchStr.startsWith('?')) {
    str = str.substr(1)
  }

  let q = str.split('&');
  q.forEach(item => {
    let a = item.split('=');
    if (a.length) {
      data[a[0]] = a[1];
    }
  })

  return data;
}

export function GetQueryString(name) {
  var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
  var r = window.location.search.substr(1).match(reg); //获取url中"?"符后的字符串并正则匹配
  var context = "";
  if (r != null)
    context = r[2];
  reg = null;
  r = null;
  return context == null || context == "" || context == "undefined" ? "" : context;
}

export const getAppConf = function () {
  const tenant = GetQueryString('tenant') || localStorage.tenantId || 'mine';
  const appName = GetQueryString('app') || localStorage.appName || 'mine';
  const clientSecret = GetQueryString('clientSecret') || localStorage.clientSecret || '123321';
  const clientId = GetQueryString('clientId') || localStorage.clientId || 'mine';
  window.appName = appName || 'mine';

  localStorage.appName = appName;
  localStorage.clientSecret = clientSecret;
  localStorage.clientId = clientId;
  return {
    clientId,
    appName,
    clientSecret,
    tenant
  }
}
