import { getUserInfo, updatepass, login, getUserAuthoritedUIEntities, getRootPathByServer, logout, findCount } from '@/services/portal.service';
import * as TYPES from './TYPES';
const { findItemById, orgMenu } = window.utils;
const EventEmitter = window.EventEmitter;
const { menuType } = window.projectConfig;
export default {
  namespaced: true,
  state: {
    authEntities: [],
    userInfo: {
    }
  },

  getters: {
    authEntities: state => state.authEntities,
    userInfo: state => state.userInfo
  },
  mutations: {
    [TYPES.AUTHENTITIES](state, authEntities) {
      state.authEntities = authEntities;
    },
    [TYPES.USER_INFO](state, userInfo) {
      state.userInfo = userInfo;
    }
  },
  actions: {
    findCount() {
      return findCount()
    },
    getRootPathByServer(){
      return getRootPathByServer();
    },
    async getUserInfo({ commit }) {
      const res = await getUserInfo();
      res.eparchyCode = res.eparchyCode.split(',') || []
      commit(TYPES.USER_INFO, res);
    },
    async updatepass({ commit }, data) {
      return await updatepass(data);
    },
    logoutPortal({ commit }, data) {
      return logout(data);
    },
    async login({ commit, dispatch }, { data }) {
      const res = await login(data);
      const session = {
        token: res.access_token || res.value,
        expire: res.expires_in || res.expiresIn,
        clientId: "test",
        staffId: res.additionalInformation && res.additionalInformation.userId
      }
      await commit('setSession', session, { root: true });

      if (window.IS_REGIST_PORTAL) {
        commit('setRootActions', [
          { name: '用户信息查看', path: '/usercenter' },
          { name: '修改密码', path: '/modifypwd' },
        ], { root: true })
      }
      await dispatch('getAuthMenus');
      await dispatch('getUserInfo');

      EventEmitter.emit('login', session)
      return res;
    },
    async getAuthMenus({ commit, rootState }) {
      if (menuType === 2) {
        commit('setRootTitles', [], { root: true });
        localStorage.removeItem('rootTitles')
      }
      const res = await getUserAuthoritedUIEntities();
      const results = orgMenu(res);
      let routes = [];
      let rootTitles = []
      if (menuType == 2) {
        routes = results;
      } else if (menuType === 3) {
        rootTitles = results;
        commit('setRootTitles', rootTitles, { root: true });
        if (rootState.rootActiveName) {
          const item = findItemById(results, rootState.rootActiveName)
          routes = item.children;
        } else {
          routes = results[0].children;
        }
      }
      // 对数据进行处理然后返回
      commit('setRoutes', routes, { root: true })
    }
  }
}
