<template>
  <div>
    <PortalLayout
      v-if="loginStatus"
      :systemName="systemName"
      :rootTitles="rootTitles"
      :rootActions="rootActions"
      :routes="routes"
      :IS_ROOT="IS_ROOT"
      :rootActiveName="rootActiveName"
      @logout="logout"
      @selectHeaderMenu="selectHeaderMenu"
    >
      <div id="content" />
      <router-view name="root" />
    </PortalLayout>
    <router-view name="portal"/>
    <BackTop :height="100" :bottom="30" v-if="loginStatus">
      <div class="top">返回顶端</div>
    </BackTop>
  </div>
</template>

<script>
let projects = (window.projectConfig || {}).projects || [];
let rootPath = window.projectConfig.rootPath || "";
import AppConfig from './app.config'
const { mapGetters, mapActions, mapState } = window.Vuex
const utils = window.utils;
import { getQuery } from '@/tools/utils'
import PortalLayout from "./Layouts/PortalLayout";
import { getAppConf } from '@/tools/utils';
const appConfig = getAppConf();
const appName = appConfig.appName;
const clientSecret = appConfig.clientSecret;
const clientId = appConfig.clientId;
export default {
  data() {
    return {
      IS_ROOT: true
    };
  },
  computed: {
    ...mapGetters([
      "rootTitles",
      "systemName",
      "routes",
      "loginStatus",
      "rootActions",
      "rootActiveName",
      "tenantId"
    ])
  },
  watch: {
    async loginStatus(n, o) {
      if (n && n != o) {
        const serverRootPath = await this.getRootPathByServer();
        let home = serverRootPath && serverRootPath.home.resource;
        if (home) {
          this.setRootPath(home)
        }
        this.$router.replace(`${home || rootPath || projects[0].path[0] || "/"}`);
      } else {
        if (this.isSpecialPage()) {
          return false;
        }
        GlobalStore.dispatch("rmSession");
        const query = getQuery(window.location.search)
        window.location.href =
          `${AppConfig.publicPath}/login`;
      }
    }
  },
  async mounted() {
    this.setRootPath(rootPath);
    if (!this.loginStatus) {
      GlobalStore.dispatch("rmSession");
      if (this.isSpecialPage()) return false;
      if (window.location.pathname.indexOf("/login") === -1) {
        const query = getQuery(window.location.search)
        window.location.href =
          `${AppConfig.publicPath}/login`;
      }
    } else {
      if (this.isSpecialPage()) {
        return false;
      }
      this.getUserInfo();
      this.getAuthMenus();
      try {
        const serverRootPath = await this.getRootPathByServer();
        let home = serverRootPath && serverRootPath.home.resource
        if (home) {
          this.setRootPath(home)
        }
        let pathname = window.location.pathname;
        if (pathname[pathname.length - 1] == '/') {
          pathname = pathname.substr(0, pathname.length - 1);
        }

        if (pathname == process.env.VUE_APP_PUBLIC_PATH) {
          this.$router.replace(`${home || rootPath || projects[0].path[0] || "/"}`);
        } else if (pathname == '') {
          this.$router.replace(`${home || rootPath || projects[0].path[0] || "/"}`);
        }
      } catch (e) {
        this.$router.replace(rootPath);
      }

    }
  },
  methods: {
    ...mapActions([
      "rmSession",
      "setRootActiveName",
      "setRoutes",
      "setRootActiveName",
      "setRootPath",
      "setTenantId"
    ]),
    ...mapActions("portal", ["getRootPathByServer"]),
    isSpecialPage () {
      const pathname = window.location.pathname;
      const isSpecial = pathname.startsWith(`${AppConfig.publicPath}/enterprise_regist`) ||
        pathname.startsWith(`${AppConfig.publicPath}/active`) ||
        pathname.startsWith(`${AppConfig.publicPath}/tip`) ||
        pathname.startsWith(`${AppConfig.publicPath}/data_charts`) ||
        pathname.startsWith(`${AppConfig.publicPath}/login`);
      return isSpecial;
    },
    ...mapActions("portal", ["getUserInfo", "getAuthMenus"]),
    logout() {
      this.rmSession();
      const query = getQuery(window.location.search)
      window.location.href = `${AppConfig.publicPath}/login`;
      this.setTenantId(appConfig.tenant || query.tenant || this.tenantId);
    },
    selectHeaderMenu(id) {
      this.setRootActiveName(id);
      this.setRoutes(utils.findItemById(this.rootTitles, id).children);
    }
  },
  components: {
    PortalLayout
  }
};
</script>
<style scoped>
.system-name {
  font-size: 30px;
}
.layout {
  border: 1px solid #d7dde4;
  background: #f5f7f9;
  position: relative;
  border-radius: 4px;
  overflow: hidden;
}

.layout-header-bar {
  background: #fff;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}
.top {
  padding: 10px;
  background: rgba(0, 0, 0, 0.6);
  color: #fff;
  text-align: center;
  border-radius: 2px;
}
</style>
